let activeAccount: any;
export const setActiveAccount = (_activeAccount: any) => {
    activeAccount = _activeAccount
}

export const getUserName = () => {
    if (activeAccount) {
        return activeAccount?.name || activeAccount?.username
    }
}

export const getUserEmail = () => {
    if (activeAccount) {
        return activeAccount.idTokenClaims?.email || activeAccount?.idTokenClaims?.login;
    }
}

export const getUserIpdId = () => {
    if (activeAccount) {
        return activeAccount?.idTokenClaims?.user_id;
    }
}