import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies, loginRequest } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';
import { PageLayout } from './components/PageLayout';
import Home from "./pages/Home"
import './styles/App.css';
import SurveyList from "./pages/SurveyList"
import About from "./pages/About"
import Run from "./pages/Run"
import Edit from "./pages/Edit"
import Results from "./pages/Results"
import store from './redux';
import { Provider } from 'react-redux';
import { setLicenseKey } from "survey-core";
// import Form from './pages/Form';
import ResultsList from './pages/ResultsList';
import Response from './pages/Response';

import Login from './pages/Login';
import FormFilling from './pages/upload';
import { registerLicense } from '@syncfusion/ej2-base';


setLicenseKey(
    "ODY0MDkxYWItYzNlOS00MGQ1LWI4ODYtYTkxNWY1YTg1MmUzOzE9MjAyNC0xMi0wNywyPTIwMjQtMTItMDcsND0yMDI0LTEyLTA3"
);
registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Udk1jWn5ccHRSR2Ne');


const Pages: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (inProgress !== InteractionStatus.None) return;
        if (!isAuthenticated && accounts.length === 0 && !location.pathname.startsWith('/run/')) {
            navigate('/')
        }
    }, [inProgress])

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {

            let result = event.payload as AuthenticationResult;
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                result.account
            ) {

                if (compareIssuingPolicy(result.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: []
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (
        <>
            <AuthenticatedTemplate>
                <Routes>
                    <Route path="/" element={<SurveyList />} />
                    {/* <Route path="/render/:id" element={<Form />}></Route> */}
                    <Route path="/upload-doc" element={<FormFilling />}></Route>
                    <Route path="/run/:id" element={<Run />}></Route>
                    <Route path="/results/:id/response/:responseId" element={<Response />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/formlist" element={<SurveyList />}></Route>
                    <Route path="/formlist/edit/:id" element={<Edit />}></Route>
                    <Route path="/formlist/results/:id" element={<Results />}></Route>
                    <Route path="/formlist/results-list/:id" element={<ResultsList />}></Route>

                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route path="/run/:id" element={<Run />}></Route>
                    <Route path="/" element={<Login />} />
                    {/* <Route path="/render/:id" element={<Form />}></Route> */}
                </Routes>
            </UnauthenticatedTemplate>
        </>
    );
};

interface AppProps {
    instance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <Provider store={store}>
                <PageLayout>
                    <Pages />
                </PageLayout>
            </Provider>
        </MsalProvider>
    );
};

export default App;
