import { useParams } from 'react-router'
import Editor from '../components/Editor'
import { useEffect, useState } from 'react';
import { get } from '../redux/surveys';
import { useReduxDispatch, useReduxSelector } from '../redux';
import { loadResultByResponseId, post, put } from '../redux/results';
import { AfterRenderPageEvent, AfterRenderQuestionEvent, ITheme, Model, SurveyModel } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { SurveyPDF } from 'survey-pdf';
import { onAfterRenderPage, onAfterRenderQuestion } from '../utils/surveyjs/surveyjs.event.util';
import { customTheme } from '../utils/surveyjs/survey_theme';
import { AddInputs } from '../utils/surveyjs/surveyjs.property.util';
import { useSearchParams } from 'react-router-dom';
import { formType } from '../utils/const';
import Breadcrumb from '../components/BreadcrumbComponent';
import { getUserEmail, getUserIpdId } from '../utils/user/user.util';

const Response = () => {
    const { id, responseId } = useParams();
    const dispatch = useReduxDispatch();
    const survey = useReduxSelector(state => state.surveys.survey)
    const [model, setModel] = useState<Model | null>(null);
    const [updateModel, setUpdateModel] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    let geoLocation = '';
    let timestamp = '';
    const [breadcrumbList, setBreadcrumbList] = useState([
        {
            text: 'Home',
            link: "/"
        }
    ]);
    const pdfDocOptions = {
        fontSize: 12,
        top: 10,
        bot: 10,
        left: 15,
        right: 15
    };

    // Use effect to get geolocation on component mount
    useEffect(() => {
        (async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.log("Geolocation not supported");
            }
        })();
    }, []);


    // Success callback for geolocation
    const success = (position: any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        timestamp = position.timestamp.toString();
        geoLocation = (JSON.stringify({ Latitude: latitude, Longitude: longitude }));
    };

    // Error callback for geolocation
    const error = (err: any) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(id as string));
            const resultAction = await dispatch(loadResultByResponseId(responseId as string));
            let _breadcrumbList = breadcrumbList
            _breadcrumbList.push({ text: `Response - ${surveyAction.payload.name}`, link: `/formlist/results-list/${surveyAction.payload.uniqueId}?creationType=${surveyAction.payload.creationType}` });
            _breadcrumbList.push({ text: `User - ${resultAction.payload.modifiedUser}`, link: '' })
            setBreadcrumbList([..._breadcrumbList])
            const survey = new Model(surveyAction.payload.json);
            survey.data = JSON.parse(resultAction.payload.content);
            if (resultAction.payload.email === getUserEmail()) {
                survey.mode = "edit";
            }
            else {
                survey.mode = "display"
            }
            setModel(survey);
            survey
                .onComplete
                .add((sender: Model) => {
                    let email = '', user_id = '';
                    email = getUserEmail()
                    user_id = getUserIpdId()
                    dispatch(post({ postId: id as string, surveyResult: sender.data, surveyResultText: JSON.stringify(sender.data), email: email, geoLocation: geoLocation, idpUserId: user_id, idpName: '', timestamp: timestamp }))
                });

            if (searchParams.get("creationType") === formType.pdfForm) {
                survey.onAfterRenderPage.add((_: SurveyModel, _options: AfterRenderPageEvent) => {
                    onAfterRenderPage(_, _options)
                })

                survey.onAfterRenderQuestion.add((_: SurveyModel, _options: AfterRenderQuestionEvent) => {
                    onAfterRenderQuestion(_, _options)
                })
                survey.applyTheme(customTheme as ITheme)
            }
            else {
                survey.addNavigationItem({
                    id: "pdf-export",
                    title: "Save as PDF",
                    action: () => savePdf(survey.data, surveyAction.payload.json)
                });
            }
            AddInputs();
            setUpdateModel(true);
        })()
    }, [dispatch, id])

    const savePdf = (surveyData: any, surveyJson: any) => {
        const surveyPdf = new SurveyPDF(surveyJson, pdfDocOptions);
        surveyPdf.data = surveyData;
        // surveyPdf.onRenderPage.add(async (survey, options: any) => {
        //     if (options.page) {
        //         // Calculate the available page height and the coordinate at which to start drawing

        //         const brickHtml = `<img src='${options.page.jsonObj.pageBackgroundImage}' width='${options.page.jsonObj.pageBackgroundImageWidth}' height='${options.page.jsonObj.pageBackgroundImageHeight}' />`;
        //         const brickRect = {
        //             xLeft: 0,
        //             xRight: options.controller.paperWidth,
        //             yTop: 0,
        //             yBot: options.controller.paperHeight,
        //         }

        //         const _HTMLBrick = new HTMLBrick(null as any, options.controller, brickRect, brickHtml, true)

        //         options.bricks.push(_HTMLBrick);

        //     }

        // });



        surveyPdf.save();
    };

    return (
        <div className='container' >
            <div className='row'>
                <div className='col-md-12' >
                    {survey.id && (<Breadcrumb data={breadcrumbList} />)}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12' >
                    {model && updateModel && (
                        <Survey model={model} id="surveyContainer" />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Response;