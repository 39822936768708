import { BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective } from "@syncfusion/ej2-react-navigations";

const Breadcrumb = (params: { data: Array<any> }) => {
    return (
       
        <BreadcrumbComponent enableNavigation={true}>
            <BreadcrumbItemsDirective>
                {params.data.map(breadcrumb => (
                        <BreadcrumbItemDirective text={breadcrumb.text} url={breadcrumb.link} />
                ))}
            </BreadcrumbItemsDirective>
        </BreadcrumbComponent>
    );
}
export default Breadcrumb;