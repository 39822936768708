import React, { useEffect, useState } from 'react'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { create } from '../redux/surveys'
import 'tabulator-tables/dist/css/tabulator.css'
import 'survey-analytics/survey.analytics.tabulator.css'
import { ColumnDirective, ColumnsDirective, Edit, GridComponent, Group, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids'
import { convertToSurveyJS } from '../utils/surveyjs/surveyjs.helper'
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import { useNavigate } from 'react-router'
import { getInputType, checkbox, dropdown, INPUTTYPE, singleCharsInput, formType } from '../utils/const'
import { DataManager, Query } from '@syncfusion/ej2-data';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { ISurveyDefinition } from '../models/survey'
import { getUserEmail } from '../utils/user/user.util'

const FormInput = (params: { inputList: Array<any>, imageList: Array<String>, pages: any }): React.ReactElement => {
  const editSettings: any = { allowEditing: true, allowAdding: false, allowDeleting: false, showAddNewRow: false, newRowPosition: 'Top' };
  const toolbarOptions: any = ['Edit', 'Update', 'Cancel'];
  const [surveyDefinition, SetSurveyDefinition] = useState<ISurveyDefinition>({ name: '', isLoginRequired: true });
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const survey = useReduxSelector(state => state.surveys.survey);
  let gridInstance: GridComponent;

  const inputType = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: new DataManager(getInputType()),
      fields: { text: "Name", value: "Value" },
      query: new Query()
    }
  };

  const createSurvey = async (): Promise<void> => {
    const jsonSurvey = convertToSurveyJS(params.inputList, params.imageList, params.pages);
    if (!surveyDefinition.isLoginRequired) {
      surveyDefinition.isMultipleSubmissionsAllow = true;
    }
    const survey = await dispatch(create({ name: surveyDefinition.name, creationType: formType.pdfForm, CreatedUser: getUserEmail(), isLoginRequired: surveyDefinition.isLoginRequired, isMultipleSubmissionsAllow: surveyDefinition.isMultipleSubmissionsAllow, Content: JSON.stringify(jsonSurvey) }));
    navigate(`/formlist/edit/${survey.payload.uniqueId}?creationType=${formType.pdfForm}`);
  }

  const actionBegin = (args: any): void => {
    if (args.requestType === 'save') {
      const index = params.inputList.findIndex(x => x.id === args.data.id)
      params.inputList[index] = args.data;
      if (params.inputList[index].choices) {
        params.inputList[index].choices = args.rowData?.choices;
      }
      gridInstance.refresh();
    }
  }

  const handleEditChange = (e: any, props: any, index: number, field: string, column: string) => {
    const value = e.value;
    if (column === "choices") {
      props.choices[index][field] = value;
    }
  };

  const choicesColEditTemplate = (props: any) => {
    if (props.column.field === "choices" && (props.inputType === checkbox || props.inputType === dropdown)) {
      return (
        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
          {
            props.choices.map((item: any, index: number) => {
              return (
                <div className="row  material small-textbox" key={index} >
                  <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <TextBoxComponent id={`inputData.choices.${index}.text`} name="text" onChange={(e: any) => handleEditChange(e, props, index, 'text', 'choices')}
                      placeholder="Text" cssClass="e-small" value={item.text} />
                  </div>
                  <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <TextBoxComponent id={`inputData.choices.${index}.value`} name="value" onChange={(e: any) => handleEditChange(e, props, index, 'value', 'choices')}
                      placeholder="Value" cssClass="e-small" value={item.value} />
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    }
    else if (props.column.field === "inputLength" && props.inputType === singleCharsInput) {
      return (<TextBoxComponent name={"inputLength"} id={"inputLength"} value={props.inputLength} type="number" cssClass="e-small" />)
    }
  };

  const choicesColTemplate = (props: any) => {
    if (props.column.field === "choices" && (props.inputType === checkbox || props.inputType === dropdown)) {
      return (
        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
          {props.choices?.map((item: any, index: string) => (
            <div key={index}>
              <span>{item.text} | {item.value}</span>
            </div>
          ))}
        </div>
      );
    }
    else if (props.column.field === "inputLength" && props.inputType === singleCharsInput) {
      return (<span>{props.inputLength}</span>)
    }
  };

  const inputTypeColTemplate = (props: any) => {
    return (
      // @ts-ignore 
      <span>{INPUTTYPE[props.inputType]}</span>
    );
  };


  return (
    <div className="control-pane">
      <div id="targetElement" className="control-section col-lg-12 defaultDialog dialog-target">

        <div className="row custom-margin">
          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className="e-input-group">
              <input value={surveyDefinition.name}
                onChange={(e) => SetSurveyDefinition({ ...surveyDefinition, name: e.target.value })} className="e-input" type="text" placeholder="Enter survey name" />
            </div>

          </div>
          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className="d-flex justify-content-start">
              <CheckBoxComponent cssClass="e-info" change={() => SetSurveyDefinition({ ...surveyDefinition, isLoginRequired: !surveyDefinition.isLoginRequired })} checked={surveyDefinition.isLoginRequired} label="Attech login form to fill form" labelPosition="Before" />
              {
                surveyDefinition.isLoginRequired && (
                  <CheckBoxComponent cssClass="e-info" change={() => SetSurveyDefinition({ ...surveyDefinition, isMultipleSubmissionsAllow: !surveyDefinition.isMultipleSubmissionsAllow })} checked={surveyDefinition.isMultipleSubmissionsAllow} label="Allow multiple submissions of form" labelPosition="Before" />
                )
              }
              <ButtonComponent className='ms-1 ' onClick={createSurvey} cssClass='e-success'>Create form</ButtonComponent>

            </div>
          </div>
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">

          </div>
        </div>
        <div>
          {
            params.inputList.length && (
              <GridComponent ref={(grid: any) => { gridInstance = grid; }} allowGrouping={true} groupSettings={{ showGroupedColumn: false, columns: ['pageNumber'] }}
                editSettings={editSettings} width='auto' dataSource={params.inputList} toolbar={toolbarOptions}
                actionBegin={actionBegin.bind(this)} allowSorting={true} enableStickyHeader={true}>
                <ColumnsDirective>
                  <ColumnDirective field='pageNumber' allowEditing={false} width='50' headerText='Page Number'></ColumnDirective>
                  <ColumnDirective field='id' allowEditing={false} width='200' headerText='Id'></ColumnDirective>
                  <ColumnDirective field='inputType' template={inputTypeColTemplate} editType='dropdownedit' edit={inputType} allowEditing={true} width='150' headerText='Type'></ColumnDirective>
                  <ColumnDirective field='name' allowEditing={true} width='200' headerText='Input Name'></ColumnDirective>
                  <ColumnDirective field='title' width='200' headerText='Fill title'></ColumnDirective>
                  <ColumnDirective field='choices' width='200' template={choicesColTemplate} editTemplate={choicesColEditTemplate} headerText='Options'></ColumnDirective>
                  <ColumnDirective field='inputLength' width='200' template={choicesColTemplate} editTemplate={choicesColEditTemplate} headerText='Input Length'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, Sort, Edit, Toolbar, Group]} />
              </GridComponent>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default FormInput