import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import '../styles/login.css';
import { Button } from 'react-bootstrap';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const Login: React.FC = () => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
        console.log(JSON.stringify(activeAccount));
        
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).then((data)=>{
        }).catch((error) => console.log(error));
    };

    return (
        <div className="login-container">
            <div id="loginForm">
                <div id="loginLogo"></div>
                <div id="basicForm" className="login">
                    <div id="login" style={{ cursor: "auto", paddingTop: "30px" }}>
                        <ButtonComponent onClick={handleLoginRedirect} cssClass='e-primary' iconCss='bi bi-box-arrow-in-right'>Sign In</ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
