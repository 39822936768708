import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiBaseAddress } from '../models/survey'
import { IResultDefinition } from '../models/result'

const initialState: {status: string, error: any, userFormResponse: any } = {
  status: 'idle',
  error: null,
  userFormResponse: null
}

export const load = createAsyncThunk('results/load', async (id: string) => {
  const response = await axios.get(apiBaseAddress + '/results?postId=' + id)
  return response.data
})

export const loadResultsByPostId = createAsyncThunk('results/resultsByPostId', async (id: string) => {
  const response = await axios.get(apiBaseAddress + '/resultsByPostId?postId=' + id)
  return response.data
})


export const loadResultByUserId = createAsyncThunk('results/loadResultByUserId', async (data: any) => {
  const response = await axios.get(`${apiBaseAddress}/loadResultByUserId?formId=${data.formId}&userId=${data.userId}`)
  return response.data
})

export const loadResultByResponseId = createAsyncThunk('results/load', async (id: string) => {
  const response = await axios.get(apiBaseAddress + '/loadResultByResponseId?id=' + id)
  return response.data
})

export const post = createAsyncThunk('results/post', async (data: any) => {
  const response = await axios.post(apiBaseAddress + '/post', data);
  return response.data
})

export const put = createAsyncThunk('results/post', async (data: { postId: string, surveyResult: any, surveyResultText: string }) => {
  const response = await axios.post(apiBaseAddress + '/updateResponse', data);
  return response.data
})

const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(loadResultByUserId.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(loadResultByUserId.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched surveys to the array
        state.userFormResponse = action.payload
      })
      .addCase(loadResultByUserId.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export default resultSlice.reducer