import { groupBy } from 'underscore'
import { checkbox, dropdown, radiogroup, singleCharsInput } from '../const';

export const updateInputs = (inputList: any[]) => {
    inputList.forEach(item => {
        if (item.inputType === singleCharsInput) {
            item.type = singleCharsInput
        }
    })
}

export const convertToSurveyJS = (inputList: any, imageList: Array<String>, pagesData: any) => {
    updateInputs(inputList);
    const surveyJS = {
        pages: [{}]
    };
    surveyJS.pages = [];
    const pages = groupBy(inputList, function (row: any) { return row.pageNumber; });
    for (let index = 0; index < pagesData.pageCount; index++) {
        surveyJS.pages.push({
            name: `page${index}`,
            pageBackgroundImage: imageList[index],
            pageBackgroundImageHeight: pagesData.pageSizes[index].height,
            pageBackgroundImageWidth: pagesData.pageSizes[index].width,
            pageBackgroundImageFit: 'contain',
            elements: pages[index + 1] ? pages[index + 1].map((d => {
                delete d.pageNumber;
                return d;
            })) : [
                {
                    "type": "expression",
                    "name": uuidv4(),
                    "titleLocation": "hidden"
                }
            ]
        })
    }
    return surveyJS;
}

export const convertToQuestionsList = (inputList: any) => {
    const uniqueInputList = groupBy(inputList, function (row: any) { return row.name; });
    const questionsList: any = [];

    Object.keys(uniqueInputList).map((item: any, index: number) => {
        const element = uniqueInputList[item][0];
        if (element.type === 'Textbox') {
            
            questionsList.push({
                id: uuidv4(),
                title: element?.tooltip === '' ? element.name : element.tooltip,
                type: element.isMultiline ? 'comment' : 'text',
                inputType: element.isMultiline ? 'comment' : 'text',
                description: '',
                name: element.name,
                pageNumber: element.pageNumber,
                positionX: element.bounds?.properties?.x || element.bounds.x,
                positionY: element.bounds?.properties?.y || element.bounds.y,
                questionInputWidth: element.bounds?.properties?.width || element.bounds?.width,
                questionInputHeight: element.bounds?.properties?.height || element.bounds?.height,
                titleLocation: "top",
                color: hexToRGBA(element.color),
                fontSize: element.fontSize,
                fontFamily: element.fontFamily,
                backgroundColor: hexToRGBA(element.backgroundColor),
                alignment: element.alignment || 'left',
                inputLength: element?.maxLength || 2,
            })
        }
        else if (element.type === "Checkbox") {
            questionsList.push({
                id: uuidv4(),
                title: element.name,
                type: checkbox,
                inputType: checkbox,
                description: '',
                name: element.name,
                pageNumber: element.pageNumber,
                titleLocation: "top",
                color: hexToRGBA(element.color),
                backgroundColor: hexToRGBA(element.backgroundColor),
                borderColor: hexToRGBA(element.borderColor),
                showSelectAllItem: false,
                choices: uniqueInputList[item].map(c => {
                    return {
                        value: c.value === '' ? c.name : c.value,
                        text: c.tooltip === '' ? c.name : c.tooltip,
                        positionX: c.bounds?.properties?.x || c.bounds.x,
                        positionY: c.bounds?.properties?.y || c.bounds.y,
                        questionInputWidth: c.bounds?.properties?.width || c.bounds?.width,
                        questionInputHeight: c.bounds?.properties?.height || c.bounds?.width,
                    }
                })
            })
        }
        else if (element.type === 'DropdownList') {
            questionsList.push({
                id: uuidv4(),
                title: element?.tooltip === '' ? element.name : element.tooltip,
                type: dropdown,
                inputType: dropdown,
                description: '',
                name: element.name,
                pageNumber: element.pageNumber,
                positionX: element.bounds?.properties?.x || element.bounds.x,
                positionY: element.bounds?.properties?.y || element.bounds.y,
                questionInputWidth: element.bounds?.properties?.width || element.bounds?.width,
                questionInputHeight: element.bounds?.properties?.height || element.bounds?.height,
                titleLocation: "top",
                color: hexToRGBA(element.color),
                fontSize: element.fontSize,
                fontFamily: element.fontFamily,
                backgroundColor: hexToRGBA(element.backgroundColor),
                alignment: element.alignment || 'left',
                choices: element.options.map((c: any) => {
                    return {
                        value: c.itemValue,
                        text: c.itemName,
                    }
                })
            })
        }
        else if (element.type === "RadioButton") {
            questionsList.push({
                id: uuidv4(),
                title: element.name,
                type: radiogroup,
                inputType: radiogroup,
                description: '',
                name: element.name,
                pageNumber: element.pageNumber,
                titleLocation: "top",
                color: hexToRGBA(element.color),
                backgroundColor: hexToRGBA(element.backgroundColor),
                borderColor: hexToRGBA(element.borderColor),
                showSelectAllItem: false,
                choices: uniqueInputList[item].map(c => {
                    return {
                        value: c.value === '' ? c.name : c.value,
                        text: c.tooltip === '' ? c.name : c.tooltip,
                        positionX: c.bounds?.properties?.x || c.bounds.x,
                        positionY: c.bounds?.properties?.y || c.bounds.y,
                        questionInputWidth: c.bounds?.properties?.width || c.bounds?.width,
                        questionInputHeight: c.bounds?.properties?.height || c.bounds?.width,
                    }
                })
            })
        }
    })
    return questionsList;

}

export const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}


export const hexToRGBA = (hex: string) => {
    if (hex.startsWith('rgba')) {
        return hex;
    }
    // Remove the leading '#' if present
    hex = hex.replace(/^#/, '');

    // Determine the length of the hex string
    let length = hex.length;

    // Normalize the hex string based on its length
    if (length === 3 || length === 4) {
        hex = hex.split('').map(char => char + char).join('');
    } else if (length !== 6 && length !== 8) {
        throw new Error('Invalid hex color format');
    }

    // Extract the RGBA components
    let red = parseInt(hex.slice(0, 2), 16);
    let green = parseInt(hex.slice(2, 4), 16);
    let blue = parseInt(hex.slice(4, 6), 16);
    let alpha = length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : 1;

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export const convertImageUrlToBase64 = async (imageUrl: string) => {
    try {
        // Fetch the image
        const response = await fetch(imageUrl);
        // Ensure the response is okay
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // Read the response as a blob
        const blob = await response.blob();
        // Create a FileReader to read the blob
        const reader = new FileReader();
        // Return a promise that resolves with the base64 string
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            // Read the blob as a data URL (base64)
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error converting image to base64:', error);
        throw error;
    }
}