import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiBaseAddress, ISurveyDefinition } from '../models/survey'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialState: { surveys: Array<ISurveyDefinition>, survey: ISurveyDefinition, status: string, surveyStatus: string, error: any } = {
  surveys: [],
  survey: {} as ISurveyDefinition,
  status: 'idle',
  surveyStatus: 'idle',
  error: null
}

const handleApiResponse = (response: any, successMessage: string, failureMessage: string) => {
  if (response.status >= 200 && response.status < 300) {
    toast.success(successMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    return response.data;
  } else {
    toast.error(failureMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw new Error(`API request failed with status ${response.status}`);
  }
};


const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    // add: (state, action: PayloadAction<void>) => {
    //     state.surveys.push(getDefaultJSON());
    // },
    // remove: (state, action: PayloadAction<string>) => {
    //     const survey = state.surveys.filter(s => s.id === action.payload)[0];
    //     const index = state.surveys.indexOf(survey);
    //     if(index >= 0) {
    //         state.surveys.splice(index, 1);
    //     }
    // },
    // update: (state, action: PayloadAction<{id: string, json: any}>) => {
    //     const survey = state.surveys.filter(s => s.id === action.payload.id)[0];
    //     survey.json = action.payload.json;
    // },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(load.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(load.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched surveys to the array
        state.surveys = state.surveys.concat(action.payload)
      })
      .addCase(load.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(create.fulfilled, (state, action) => {
        // state.status = 'succeeded'
        // Add new survey to the array
        state.surveys.unshift(action.payload)
        state.survey = action.payload;
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Remove survey from the array
        const survey = state.surveys.filter(s => s.uniqueId === action.payload.id)[0];
        const index = state.surveys.indexOf(survey);
        if (index >= 0) {
          state.surveys.splice(index, 1);
        }
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Update survey in the array
        const survey = state.surveys.length ? state.surveys.filter(s => s.uniqueId === action.payload.uniqueId)[0] : action.payload;
        survey.json = action.payload.json;
      })
      .addCase(get.pending, (state, action) => {
        state.surveyStatus = 'loading'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.surveyStatus = 'succeeded'
        state.survey = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.surveyStatus = 'failed'
        state.error = action.error.message
      })
  }
})

const api = axios.create();

api.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('accessToken'); // Replace with your actual function to get the access token
    if (accessToken) {
      console.log('Adding authorization');
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const { reset } = surveysSlice.actions;

export const load = createAsyncThunk('surveys/load', async () => {
  const response = await api.get(apiBaseAddress + '/getActive')
  return response.data
})

export const get = createAsyncThunk('surveys/get', async (id: string) => {
  const response = await api.get(apiBaseAddress + '/getSurvey?surveyId=' + id)
  return response.data
})

export const getbykey = createAsyncThunk('surveys/getbykey', async (key: string) => {
  const response = await api.get(apiBaseAddress + '/getSurveyByKey?surveykey=' + key)
  return response.data
})

export const create = createAsyncThunk('surveys/create', async (data: any) => {
  try {
    const response = await api.post(`${apiBaseAddress}/create`, data);
    return handleApiResponse(response, 'Create successful!', 'Create failed!');
  } catch (error) {
    toast.error('Create failed!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw error;
  }
})

export const updateFormLoginRequired = createAsyncThunk('surveys/updateFormLoginRequired', async (data: any) => {
  try {
    const response = await api.get(`${apiBaseAddress}/updateFormLoginRequired?id=${data}`);
    return handleApiResponse(response, 'Update successful!', 'Form');
  } catch (error) {
    toast.error('Create failed!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw error;
  }
})

export const updateFormSubmissions = createAsyncThunk('surveys/updateFormSubmissions', async (data: any) => {
  try {
    const response = await api.get(`${apiBaseAddress}/updateFormSubmissions?id=${data}`);
    return handleApiResponse(response, 'Update successful!', 'Form');
  } catch (error) {
    toast.error('Create failed!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw error;
  }
})


export const remove = createAsyncThunk('surveys/delete', async (id: string) => {
  try {
    const response = await api.get(`${apiBaseAddress}/delete?id=${id}`);
    return handleApiResponse(response, 'Delete successful!', 'Delete failed!');
  } catch (error) {
    toast.error('Delete failed!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw error;
  }
})

export const update = createAsyncThunk('surveys/update', async (data: { id: string, json: any, text: string }) => {
  try {
    const response = await api.post(`${apiBaseAddress}/changeJson`, data);
    return handleApiResponse(response, 'Update successful!', 'Update failed!');
  } catch (error) {
    toast.error('Update failed!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    throw error;
  }
})

// export const { add, remove, update } = surveysSlice.actions
export default surveysSlice.reducer