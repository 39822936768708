import React, { useEffect, useMemo, useState } from 'react'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { create, get, update } from '../redux/surveys'
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'
import { ITheme, Serializer } from "survey-core";
import 'survey-creator-core/survey-creator-core.css'
import { AddInputs, addPropertyToPage, addPropertyToQuestion } from '../utils/surveyjs/surveyjs.property.util';
import { onSurveyInstanceCreated } from '../utils/surveyjs/surveyjs.event.util';
import { customTheme } from '../utils/surveyjs/survey_theme';
import Breadcrumb from './BreadcrumbComponent';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogUtility } from '@syncfusion/ej2-react-popups';
import { formType } from '../utils/const';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useNavigate } from 'react-router';

const Editor = (params: { id: string, type: string }): React.ReactElement => {

  const [breadcrumbList, setBreadcrumbList] = useState([
    {
      text: 'Home',
      link: "/"
    },
    {
      text: 'My Forms',
      link: "/formlist"
    }
  ]);
  const [content, setContent] = useState<string>('');
  const [display, setDisplay] = useState<string>('');
  let dialogObj: any;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch()
  const survey = useReduxSelector(state => state.surveys.survey);
  const creator = useMemo(() => {
    const options = {
      showLogicTab: true,
      showTranslationTab: true,
      showThemeTab: true
    };
    const _creator = new SurveyCreator(options);
    if (params.type === formType.pdfForm) {
      onSurveyInstanceCreated(_creator);
      _creator.theme = customTheme as ITheme;

      addPropertyToPage(Serializer);
      addPropertyToQuestion(Serializer);
      AddInputs();
    }
    return _creator;
  }, []);

  creator.isAutoSave = false;
  creator.saveSurveyFunc = (saveNo: number, callback: (no: number, success: boolean) => void) => {
    dispatch(update({ id: params.id, json: creator.JSON, text: creator.text }))
    callback(saveNo, true);
  }

  useEffect(() => {
    (async () => {
      const surveyAction = await dispatch(get(params.id));
      let _breadcrumbList = breadcrumbList
      _breadcrumbList.push({ text: surveyAction.payload.name, link: '' })
      setBreadcrumbList([..._breadcrumbList])
      if (typeof surveyAction.payload.json === 'object') {
        creator.JSON = surveyAction.payload.json
      } else {
        creator.text = surveyAction.payload.json
      }
    })()
  }, [dispatch, creator, params.id])

  const buttonClick = (args: any) => {
    if (args.target.textContent.toUpperCase() == 'CANCEL') {
      setDisplay('none');
      dialogObj = DialogUtility.confirm({
        title: ' Discard changes',
        content: "Are you sure you want to discard the changes?",
        okButton: { click: confirmOkAction.bind(this) },
        showCloseIcon: true,
        cancelButton: { click: confirmCancelAction.bind(this) },
        position: { X: 'center', Y: 'center' },
        closeOnEscape: true
      });
    }
    else if (args.target.textContent.toUpperCase() == 'SAVE AS') {
      setDisplay('none');
      dialogObj = DialogUtility.confirm({
        title: 'Copy Form',
        content: `<p>Enter form name: </p><input id= "inputEle" type="text" value='copy of ${survey.name}' name="Required" class="e-input" placeholder="Type here.." />`,
        okButton: { text: "Save", click: promptOkAction.bind(this) },
        showCloseIcon: true,
        cancelButton: { click: promptCancelAction.bind(this) },
        position: { X: 'center', Y: 'center' },
        closeOnEscape: true
      });
    }
    else if (args.target.textContent.toUpperCase() == 'SAVE') {
      dispatch(update({ id: params.id, json: creator.JSON, text: creator.text }));
    }
  }

  const confirmOkAction = () => {
    dialogObj.hide();
    navigate('/formlist')
    setDisplay('block');
  }

  const confirmCancelAction = () => {
    dialogObj.hide();
    setDisplay('block');
  }

  const promptOkAction = () => {
    let value: string;
    value = (document.getElementById("inputEle") as any).value;
    dispatch(create({ name: value, creationType: params.type, Content: JSON.stringify(creator.JSON) }));
    setDisplay('block');
  }

  const promptCancelAction = () => {
    dialogObj.hide();
    setContent("The user canceled the prompt dialog");
    setDisplay("block");
  }

  return (<>

    <div className='row col-12'>
      <div className="d-flex justify-content-between">
        <div>
          {survey.id && (<Breadcrumb data={breadcrumbList} />)}
        </div>
        <div>
          <div>
            <ProgressButtonComponent content="SAVE"  iconCss='e-icons e-save' onClick={buttonClick.bind(this)} cssClass='e-success' isPrimary spinSettings={{ position: 'Right' }}></ProgressButtonComponent>
            <ButtonComponent className='m-1' iconCss='e-icons e-save-as' cssClass='e-info' onClick={buttonClick.bind(this)}>SAVE AS</ButtonComponent>
            <ButtonComponent cssClass='e-warning' iconCss='e-icons e-circle-close' onClick={buttonClick.bind(this)}>CANCEL</ButtonComponent>
          </div>
        </div>
      </div>
    </div>
    <SurveyCreatorComponent creator={creator} />
  </>)
}

export default Editor