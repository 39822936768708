import { useParams } from 'react-router'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { loadResultByUserId, post } from '../redux/results'
import { AfterRenderPageEvent, AfterRenderQuestionEvent, ITheme, Model, StylesManager, SurveyModel } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.css'
import { onAfterRenderPage, onAfterRenderQuestion, onSurveyInstanceCreated } from '../utils/surveyjs/surveyjs.event.util'
import { options } from 'survey-analytics/survey-analytics.types/utils'
import { customTheme } from '../utils/surveyjs/survey_theme'
import { AddInputs } from '../utils/surveyjs/surveyjs.property.util'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from '../redux/surveys'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { formType } from '../utils/const'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import './Run.css'
import { getUserEmail, getUserIpdId } from '../utils/user/user.util'
StylesManager.applyTheme("defaultV2")

const Run = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()
    const survey = useReduxSelector(state => state.surveys.survey)
    const surveyStatus = useReduxSelector(state => state.surveys.surveyStatus)
    const userFormResponse = useReduxSelector(state => state.results.userFormResponse)

    const [searchParams] = useSearchParams();
    const [model, setModel] = useState<Model | null>(null);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [geoLocation, setGeoLocation] = useState<string>('');
    const [timestamp, setTimestamp] = useState<string>('');
    const [idpName, setIdpName] = useState<string>('');

    const { instance, inProgress } = useMsal();
    let activeAccount: any;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const openLoginPopup = () => {
        instance.loginPopup().then(async (data: any) => {
            //  setShowForm(true);
            loadUserResponse(data.idTokenClaims.user_id)
        }).catch((error) => console.log(error));
    }

    const loadUserResponse = async (user_id: string) => {
        dispatch(loadResultByUserId({ formId: id, userId: user_id }));
    }

    useEffect(() => {
        (async () => {
            if (survey.id) {
                const _model = new Model(survey.json);
                _model
                    .onComplete
                    .add((sender: Model) => {
                        let email = '', user_id = '';
                        email = getUserEmail()
                        user_id = getUserIpdId()
                        dispatch(post({ postId: id as string, surveyResult: sender.data, surveyResultText: JSON.stringify(sender.data), email: email, geoLocation: geoLocation, idpUserId: user_id, idpName: idpName, timestamp: timestamp }))
                    });
                if (searchParams.get("creationType") === formType.pdfForm) {
                    _model.onAfterRenderPage.add((_: SurveyModel, _options: AfterRenderPageEvent) => {
                        onAfterRenderPage(_, _options)
                    })
                    _model.onAfterRenderQuestion.add((_: SurveyModel, _options: AfterRenderQuestionEvent) => {
                        onAfterRenderQuestion(_, _options)
                    })
                    _model.applyTheme(customTheme as ITheme)
                }
                setModel(_model)
            }
        })()
    }, [survey])

    useEffect(() => {
        if (survey.id) {
            if (!survey.isLoginRequired) {
                setShowForm(true);
            }
            else {
                if (activeAccount?.idToken) {
                    loadUserResponse(activeAccount.idTokenClaims.user_id);
                }
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(success, error);
                } else {
                    console.log("Geolocation not supported");
                }
            }
        }

    }, [survey])

    useEffect(() => {
        if (model && userFormResponse) {
            const _model = model
            if (!survey.isMultipleSubmissionsAllow) {
                _model.mode = "display";
            }
            _model.data = JSON.parse(userFormResponse.content)
            setModel(_model)
        }
    }, [userFormResponse])

    const success = (position: any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setTimestamp(position.timestamp.toString())
        setGeoLocation(JSON.stringify({ Latitude: latitude, Longitude: longitude }));
    }

    const error = () => {
        console.log("Unable to retrieve your location");
    }

    useEffect(() => {
        (async () => {
            await dispatch(get(id || ''))
            AddInputs();
        })()
    }, [dispatch, id])

    useEffect(() => {
        (async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.log("Geolocation not supported");
            }
        })()
    }, [])

    return (
        <div className='container'>
            {surveyStatus === "loading" && (
                <div className="row d-flex justify-content-center">
                    <div className="col-6" >
                        <p className='displayText'>Loading</p>
                        <SkeletonComponent id='skeletonText' shape='Text' width='100%' height='15px'></SkeletonComponent><br />
                        <SkeletonComponent id='skeletonTextMedium' width='30%' height='15px'></SkeletonComponent><br />
                        <SkeletonComponent id='skeletonTextSmall' width='15%' height='15px'></SkeletonComponent><br />
                        <SkeletonComponent id='skeletonTextMedium1' width='60%' height='15px'></SkeletonComponent><br />
                        <SkeletonComponent id='skeletonTextSmall1' width='15%' height='15px'></SkeletonComponent>
                    </div>
                </div>
            )}
            {!showForm && (activeAccount?.idToken) && survey.isLoginRequired &&
                (
                    <div className="row d-flex justify-content-center">
                        <div className="col-4">
                            <div className="e-card profile" style={{ justifyContent: 'flex-start' }}>
                                <div className="e-card-header">
                                    <div className="e-card-header-image e-card-corner">
                                        <span className="e-card-content e-ddb-icons e-profile"></span>
                                    </div>
                                </div>
                                <div className="e-card-header">
                                    <div className="e-card-header-caption center">
                                        <div className="e-card-header-title">User Login With</div>
                                        <div className="e-card-sub-title">{getUserEmail()}</div>
                                    </div>
                                </div>
                                <div className="e-card-separator"></div>

                                {(!userFormResponse?.id || survey.isMultipleSubmissionsAllow) && (<div className="e-card-content">Click the  <b className='text-uppercase'>Fill Form</b>  button to submit the form using the {getUserEmail()} account </div>)}
                                {userFormResponse?.id && survey.isMultipleSubmissionsAllow && <div className="e-card-content">You filled out this form on {new Date(userFormResponse.createdDate).toLocaleString('en-GB')}. Click the <b className='text-uppercase'> Fill Form  </b> button to continure editing</div>}
                                {userFormResponse?.id && !survey.isMultipleSubmissionsAllow && <div className="e-card-content">You completed this form on {new Date(userFormResponse.createdDate).toLocaleString('en-GB')}. To view the form, please click on <b className='text-uppercase'>view submitted form</b>. Note that edits are not allowed</div>}

                                <div className="e-card-content">Click the  <b className='text-uppercase'> Login  </b>button to fill out the form with another account.</div>
                                <div className="e-card-actions center d-flex justify-content-evenly">
                                    {(!userFormResponse?.id ||  survey.isMultipleSubmissionsAllow) && survey.isMultipleSubmissionsAllow && (<ButtonComponent onClick={() => { setShowForm(true) }} iconCss='e-icons e-edit-notes' cssClass='e-primary'>Fill Form</ButtonComponent>)}
                                    {!survey.isMultipleSubmissionsAllow && (<ButtonComponent onClick={() => { setShowForm(true) }} iconCss='bi bi-file-text-fill' cssClass='e-primary'>View Submitted Form</ButtonComponent>)}
                                    <ButtonComponent onClick={openLoginPopup} iconCss='e-icons e-lock' cssClass='e-primary'>Login</ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {!showForm && (!activeAccount?.idToken) && survey.isLoginRequired &&
                (
                    <><div className="row d-flex justify-content-center">
                        <div className="col-4">
                            <div className="e-card profile" style={{ justifyContent: 'flex-start' }}>
                                <div className="e-card-header">
                                    <div className="e-card-header-image e-card-corner">
                                        <span className="e-card-content e-icons e-lock "></span>
                                    </div>
                                </div>
                                <div className="e-card-header">
                                    <div className="e-card-header-caption center">
                                        <div className="e-card-header-title">Login Required</div>
                                        <div className="e-card-sub-title">{survey.name}</div>
                                    </div>
                                </div>
                                <div className="e-card-separator"></div>
                                <div className="e-card-content">You need to log in first to fill out this form.</div>
                                <div className="e-card-actions center">
                                    <ButtonComponent onClick={openLoginPopup} iconCss='e-icons e-lock' cssClass='e-primary'>Login</ButtonComponent>

                                </div>
                            </div>
                        </div>
                    </div></>
                )
            }
            {model && showForm &&

                <><div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <p className='displayText'>{survey.id ? survey.name : "Loading..."}</p>
                    </div>
                </div><Survey model={model} />
                </>}
        </div>
    );
}

export default Run;