export interface ISurveyDefinition {
    id?: string,
    name?: string,
    json?: any,
    uniqueId?: string,
    creationType?: string,
    isLoginRequired?: boolean,
    isMultipleSubmissionsAllow? : boolean
    CreatedUser?: string
}

export const defaultJSON = {
    id: '',
    name: 'New Survey',
    json: {
        elements: [
            { type: 'radiogroup', name: 'question1', choices: ['1', '2', '3'] }
        ]
    }
}

export var apiBaseAddress = process.env.REACT_APP_API_URL + '/api';
export const apiHost = process.env.REACT_APP_API_URL;