import { combineReducers } from '@reduxjs/toolkit'
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { History } from 'history'
import surveysReducer from './surveys'
import resultsReducer from './results'

const rootReducer = (history: History) =>
    combineReducers({
        surveys: surveysReducer,
        results: resultsReducer,
        router: createRouterReducer(history),
    })

export default rootReducer