import { AfterRenderPageEvent, AfterRenderQuestionEvent, JsonMetadata, SurveyModel } from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { checkbox, comment, dropdown, radiogroup, singleCharsInput } from "../const";

export const onSurveyInstanceCreated = (_creator: SurveyCreator) => {
    _creator.onSurveyInstanceCreated.add((_, options) => {
        if (options.area === "preview-tab") {
            options.survey.onAfterRenderPage.add((_, _options) => {
                onAfterRenderPage(_, _options);
            })

            options.survey.onAfterRenderQuestion.add((_, _options: AfterRenderQuestionEvent) => {
                onAfterRenderQuestion(_, _options);
            })
        }
        // if (options.area === "designer-tab") {
        //     debugger
        //     options.survey.onAfterRenderPage.add((_, _options) => {
        //         onAfterRenderPage(_, _options);
        //     })

        //     options.survey.onAfterRenderQuestion.add((_, _options: AfterRenderQuestionEvent) => {
        //         onAfterRenderQuestion(_, _options);
        //     })
        // }

    });
}


export const onAfterRenderPage = (_: SurveyModel, _options: AfterRenderPageEvent) => {
    const element = document.getElementsByClassName("sd-body__page")[0] as HTMLElement
    const page = _options.page as any
    element.style.backgroundImage = "url(" + page.jsonObj["pageBackgroundImage"] || '' + ")";
    element.style.padding = "0px";
    element.style.height = `${page.jsonObj["pageBackgroundImageHeight"]}px`;
    element.style.width = `${page.jsonObj["pageBackgroundImageWidth"]}px`;
    element.style.backgroundSize = page.jsonObj["pageBackgroundImageFit"] ? page.jsonObj["pageBackgroundImageFit"].toLowerCase() : 'contain';
}

export const onAfterRenderQuestion = (_: SurveyModel, _options: AfterRenderQuestionEvent) => {
    const question = _options.question["jsonObj"] as any;
    const question__header = _options.htmlElement.getElementsByClassName('sd-question__header')[0] as HTMLElement;
    if (question__header) {
        question__header.style.display = 'none';
    }

    if (question["type"] === "text") {
        _options.htmlElement.getElementsByTagName('input')[0].style.border = 'none';
        _options.htmlElement.getElementsByTagName('input')[0].style.backgroundColor = "transparent"; //question['backgroundColor'];
        _options.htmlElement.getElementsByTagName('input')[0].style.fontSize = question["fontSize"] + "px"
        _options.htmlElement.getElementsByTagName('input')[0].style.color = question["color"]
        _options.htmlElement.getElementsByTagName('input')[0].style.fontFamily = question["fontFamily"]
        _options.htmlElement.getElementsByTagName('input')[0].style.alignContent = question["alignment"]
        _options.htmlElement.getElementsByTagName('input')[0].style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.getElementsByTagName('input')[0].style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.getElementsByTagName('input')[0].style.padding = "0px"

        _options.htmlElement.style.position = 'absolute';
        _options.htmlElement.style.left = question["positionX"] ? question["positionX"].toString() + "px" : '';
        _options.htmlElement.style.top = question["positionY"] ? question["positionY"].toString() + "px" : '';
        _options.htmlElement.style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.style.fontSize = question["fontSize"] + "px"


    }
    else if (question["type"] === comment) {
        _options.htmlElement.getElementsByTagName('textarea')[0].style.border = 'none';
        _options.htmlElement.getElementsByTagName('textarea')[0].style.backgroundColor = "transparent"; //question['backgroundColor'];
        _options.htmlElement.getElementsByTagName('textarea')[0].style.fontSize = question["fontSize"] + "px"
        _options.htmlElement.getElementsByTagName('textarea')[0].style.color = question["color"]
        _options.htmlElement.getElementsByTagName('textarea')[0].style.fontFamily = question["fontFamily"]
        _options.htmlElement.getElementsByTagName('textarea')[0].style.alignContent = question["alignment"]
        _options.htmlElement.getElementsByTagName('textarea')[0].style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.getElementsByTagName('textarea')[0].style.minHeight = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.getElementsByTagName('textarea')[0].style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.getElementsByTagName('textarea')[0].style.padding = "0px"

        _options.htmlElement.style.position = 'absolute';
        _options.htmlElement.style.left = question["positionX"] ? question["positionX"].toString() + "px" : '';
        _options.htmlElement.style.top = question["positionY"] ? question["positionY"].toString() + "px" : '';
        _options.htmlElement.style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.style.fontSize = question["fontSize"] + "px"


    }
    else if (question["type"] === checkbox) {
        const nodes = _options.htmlElement.getElementsByClassName('sd-selectbase')[0].childNodes as any;
        let index = 0;
        for (const element of nodes) {
            if (element.nodeName.toLowerCase() == 'div' && element.classList.contains('sd-checkbox')) {
                element.style.padding = "0px";
                if (element.firstChild.classList.contains('sd-selectbase__label')) {
                    const checkBoxElement = element.getElementsByClassName('sd-checkbox__decorator')[0];
                    checkBoxElement.style.width = question.choices[index]["questionInputWidth"] ? question.choices[index]["questionInputWidth"].toString() + "px" : 'auto';
                    checkBoxElement.style.height = question.choices[index]["questionInputHeight"] ? question.choices[index]["questionInputHeight"].toString() + "px" : 'auto';
                    //checkBoxElement.style.backgroundColor = 'transparent';
                    checkBoxElement.style.border = `solid 0.5px ${question["borderColor"]}`


                    element.style.width = question.choices[index]["questionInputWidth"] ? question.choices[index]["questionInputWidth"].toString() + "px" : 'auto';
                    element.style.height = question.choices[index]["questionInputHeight"] ? question.choices[index]["questionInputHeight"].toString() + "px" : 'auto';
                    element.style.left = question.choices[index]["positionX"] ? question.choices[index]["positionX"].toString() + "px" : 'auto';
                    element.style.top = question.choices[index]["positionY"] ? question.choices[index]["positionY"].toString() + "px" : 'auto';
                    element.style.position = 'absolute';
                    index++;
                }
            }
        }
    }
    else if (question["type"] === radiogroup) {
        const nodes = _options.htmlElement.getElementsByClassName('sd-selectbase')[0].childNodes as any;
        let index = 0;
        for (const element of nodes) {
            if (element.nodeName.toLowerCase() == 'div' && element.classList.contains('sd-radio')) {
                element.style.padding = "0px";
                if (element.firstChild.classList.contains('sd-selectbase__label')) {
                    const checkBoxElement = element.getElementsByClassName('sd-radio__decorator')[0];
                    checkBoxElement.style.width = question.choices[index]["questionInputWidth"] ? question.choices[index]["questionInputWidth"].toString() + "px" : 'auto';
                    checkBoxElement.style.height = question.choices[index]["questionInputHeight"] ? question.choices[index]["questionInputHeight"].toString() + "px" : 'auto';
                    //checkBoxElement.style.backgroundColor = 'transparent';
                    checkBoxElement.style.border = `solid 0.5px ${question["borderColor"]}`


                    element.style.width = question.choices[index]["questionInputWidth"] ? question.choices[index]["questionInputWidth"].toString() + "px" : 'auto';
                    element.style.height = question.choices[index]["questionInputHeight"] ? question.choices[index]["questionInputHeight"].toString() + "px" : 'auto';
                    element.style.left = question.choices[index]["positionX"] ? question.choices[index]["positionX"].toString() + "px" : 'auto';
                    element.style.top = question.choices[index]["positionY"] ? question.choices[index]["positionY"].toString() + "px" : 'auto';
                    element.style.position = 'absolute';
                    index++;
                }
            }
        }
    }
    else if (question["type"] === dropdown) {
        const deopdownElement = _options.htmlElement.getElementsByClassName('sd-dropdown')[0] as HTMLElement
        deopdownElement.style.padding = "0px";
        deopdownElement.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';

        _options.htmlElement.style.position = 'absolute';
        _options.htmlElement.style.left = question["positionX"] ? question["positionX"].toString() + "px" : '';
        _options.htmlElement.style.top = question["positionY"] ? question["positionY"].toString() + "px" : '';
        _options.htmlElement.style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.style.fontSize = question["fontSize"] + "px"

        if (_options.htmlElement.getElementsByTagName('input')[0]) {
            _options.htmlElement.getElementsByTagName('input')[0].style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
            _options.htmlElement.getElementsByTagName('input')[0].style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        }

    }
    else if (question["type"] === singleCharsInput) {
        const nodes = _options.htmlElement.getElementsByClassName('single-chars-input')[0].childNodes as any;
        const width = question["questionInputWidth"] / question.inputLength;
        let index = 0;
        for (const element of nodes) {
            if (element.nodeName.toLowerCase() == 'div' && element.classList.contains('input-char')) {
                element.firstChild.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
                element.firstChild.style.width = `${width}px`;
                element.firstChild.style.background = "transparent"; //question['backgroundColor'];
                element.firstChild.style.borderRadius = "0px"
                element.firstChild.style.padding = "0px"
            }
        }
        _options.htmlElement.style.position = 'absolute';
        _options.htmlElement.style.left = question["positionX"] ? question["positionX"].toString() + "px" : '';
        _options.htmlElement.style.top = question["positionY"] ? question["positionY"].toString() + "px" : '';
        _options.htmlElement.style.width = question["questionInputWidth"] ? question["questionInputWidth"].toString() + "px" : 'auto';
        _options.htmlElement.style.height = question["questionInputHeight"] ? question["questionInputHeight"].toString() + "px" : 'auto';
        _options.htmlElement.style.fontSize = question["fontSize"] + "px"

    }
}