import { useNavigate, useParams } from 'react-router'
import { useReduxDispatch, useReduxSelector } from '../redux';
import { loadResultsByPostId } from '../redux/results';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { get } from '../redux/surveys';
import Breadcrumb from '../components/BreadcrumbComponent';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort, VirtualScroll } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const ResultsList = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()
    let gridInstance: GridComponent;
    const navigate = useNavigate();
    const survey = useReduxSelector(state => state.surveys.survey)
    const [results, setResults] = useState();
    const [breadcrumbList, setBreadcrumbList] = useState([
        {
            text: 'Home',
            link: "/"
        }
    ]);
    const gridFilter: any = {
        type: 'Menu'
    }

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(id as string));
            const resultsAction = await dispatch(loadResultsByPostId(id as string))
            setResults(resultsAction.payload)
            let _breadcrumbList = breadcrumbList
            _breadcrumbList.push({ text: `Response - ${surveyAction.payload.name}`, link: '' })
            setBreadcrumbList([..._breadcrumbList])
        })()
    }, [dispatch, id])

    const groLocationTemplate = (props: any) => {
        if (props.geoLocation) {
            return (
                <>
                    <p className='m-1'><span className='e-icons e-location'>{' '}</span><span>Latitude : {props.geoLocation ? JSON.parse(props.geoLocation).Latitude : ''}</span> <br /></p>
                    <p className='m-1'><span className='e-icons e-location'>{' '}</span><span>Longitude : {props.geoLocation ? JSON.parse(props.geoLocation).Longitude : ''}</span></p>
                </>
            )
        }
        else {
            <span>-</span>
        }

    }

    const viewResponseTemplate = (props: any) => {
        return (
            <Link to={`/results/${survey.uniqueId}/response/${props.id}?creationType=${survey.creationType || 'survey'}`}><ButtonComponent iconCss='e-icons e-page-column' cssClass='e-info'>View Filled Form</ButtonComponent> </Link>
        ) 
    }

    const TimezoneTemplate = (props: any) => {
        return (
            <div>
                {
                    `${new Date(parseInt(props.timestamp, 10)).toLocaleString('en-GB')}`
                }
            </div>
        )
    }

    const created = () => {
        gridInstance.showMaskRow();

    };
    return (<div className='container'>
        <div className='row'>
            <div className='col-md-12' >
                {survey.id && (<Breadcrumb data={breadcrumbList} />)}
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <GridComponent id="overviewgrid" ref={(grid: any) => { gridInstance = grid; }} created={created} dataSource={results} pageSettings={{ pageSize: 10, pageCount: 10 }} loadingIndicator={{ indicatorType: 'Shimmer' }} enableHover={false}  rowHeight={38} height='75vh' filterSettings={gridFilter} allowFiltering={true} allowSorting={true} allowSelection={true} enableHeaderFocus={true}>
                    <ColumnsDirective>
                        <ColumnDirective field='email' headerText='User' isPrimaryKey={true} ></ColumnDirective>
                        <ColumnDirective field='timestamp' headerText='Timezone' template={TimezoneTemplate} />
                        <ColumnDirective field='geoLocation' headerText='Geo Location' template={groLocationTemplate} />
                        <ColumnDirective field='Acrion' headerText='View Response' template={viewResponseTemplate} ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Filter, Page, Sort]} />
                </GridComponent>
            </div>
        </div>
    </div>);
}

export default ResultsList;