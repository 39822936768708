import { JsonMetadata } from "survey-core";
import { checkbox, radiogroup } from "../const";
import { ReactQuestionFactory } from "survey-react-ui";
import React from "react";
import { CUSTOM_TYPE, SurveyQuestionSingleCharsInput } from "../../components/SurveyCreatorComponent";

export const addPropertyToPage = (Serializer: JsonMetadata) => {
    Serializer.addProperty("page", {
        name: "pageBackgroundImage",
        displayName: "Page background image",
        type: "file",
        isRequired: false,
        category: "general",
        visibleIndex: 3
    });

    Serializer.addProperty("page", {
        name: "pageBackgroundImageFit",
        type: "string",
        displayName: "Page background image fit",
        choices: [
            { value: "unset", text: "None" },
            { value: "contain", text: "Contain" },
            { value: "cover", text: "Cover" },
            { value: "static", text: "Static" },
        ],
        default: "contain",
        category: "general",
        visibleIndex: 4
    });

    Serializer.addProperty("page", {
        name: "pageBackgroundImageHeight",
        type: "number",
        displayName: "Page background image height",
        category: "general",
        visibleIndex: 5,
    });

    Serializer.addProperty("page", {
        name: "pageBackgroundImageWidth",
        type: "number",
        displayName: "Page background image width",
        category: "general",
        visibleIndex: 6,
    });
}

export const addPropertyToItemValue = (Serializer: JsonMetadata) => {
    Serializer.addProperty("itemvalue", {
        name: "positionX:number",
        default: 0,
    });

    Serializer.addProperty("itemvalue", {
        name: "positionY:number",
        default: 0
    });

    Serializer.addProperty("itemvalue", {
        name: "questionInputWidth:number",
        default: 0
    });

    Serializer.addProperty("itemvalue", {
        name: "questionInputHeight:number",
        default: 0
    });

}

export const addPropertyToQuestion = (Serializer: JsonMetadata) => {

    addPropertyToItemValue(Serializer);

    Serializer.addProperty("question", {
        name: "id",
        type: "string",
        displayName: "Question Id",
        category: "Input element setting",
        readOnly: true
    });

    Serializer.addProperty("question", {
        name: "positionX",
        type: "number",
        displayName: "Question input positionX",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "positionY",
        type: "number",
        displayName: "Question input positionY",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "questionInputWidth",
        type: "number",
        displayName: "Input width",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "questionInputHeight",
        type: "number",
        displayName: "Input height",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "color",
        type: "color",
        displayName: "Font color",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "fontSize",
        type: "number",
        displayName: "Input font size",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "fontFamily",
        type: "string",
        displayName: "Input font family",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "backgroundColor",
        type: "color",
        displayName: "Input background color",
        category: "Input element setting",
    });

    Serializer.addProperty("question", {
        name: "alignment",
        type: "number",
        displayName: "Input alignment",
        category: "Input element setting",
        choices: [
            { value: "left", text: "Left" },
            { value: "right", text: "Right" },
            { value: "center", text: "Center" },
        ],
        default: "left"
    });

    Serializer.addProperty("question", {
        name: "borderColor",
        type: "color",
        displayName: "Input border color",
        category: "Input element setting",
    });
}

export const AddInputs = () => {
    // Register `SurveyQuestionSingleCharsInput` as a class that renders `single-chars-input` questions 
    ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
        return React.createElement(SurveyQuestionSingleCharsInput, props);
    });

}
