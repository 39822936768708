import { useParams } from 'react-router'
import Editor from '../components/Editor'
import { useSearchParams } from 'react-router-dom';

const Edit = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    return (<>
        <div className='sjs-editor-container' style={{height:'85vh'}}>
            <Editor id={id as string} type={searchParams.get('creationType') as string}/>
        </div>
    </>);
}


export default Edit;