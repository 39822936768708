import React, { useEffect, useState } from 'react';
import { load, remove, reset, updateFormLoginRequired, updateFormSubmissions } from '../redux/surveys';
import { useReduxDispatch, useReduxSelector } from '../redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Surveys.css';
import AddSurvey from './AddSurvey'; // Import your modal component
import { ButtonComponent, CheckBoxComponent, SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { BeforeOpenEventArgs, DialogComponent, DialogUtility, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Sort, Inject, FilterSettingsModel, Filter, Page } from '@syncfusion/ej2-react-grids';
import { formType } from '../utils/const';
import { DropDownButtonComponent, ItemModel, MenuEventArgs } from '@syncfusion/ej2-react-splitbuttons';


const Surveys = (): React.ReactElement => {
    const surveys = useReduxSelector(state => state.surveys.surveys)
    const dispatch = useReduxDispatch();
    const postStatus = useReduxSelector(state => state.surveys.status)
    const [dialogStatus, setDialogStatus] = React.useState({ hideDialog: false });
    const survey = useReduxSelector(state => state.surveys.survey);
    const filterSettings: FilterSettingsModel = { type: 'Excel' };
    let gridInstance: GridComponent;
    const [display, setDisplay] = useState<string>('');
    let dialogObj: any;

    useEffect(() => {
        if (postStatus === 'idle') {
            dispatch(load())
        }
        console.log(surveys)
    }, [postStatus, dispatch])

    useEffect(() => {
        return () => {
            dispatch(reset())
        };
    }, []);

    useEffect(() => {
        if (survey.id) {
            dialogClose();
        }
    }, [survey])

    const deleteForm = (data: any) => {
        setDisplay('none');
        dialogObj = DialogUtility.confirm({
            title: ' Delete Form',
            content: "Are you sure you want to permanently delete this form?",
            okButton: { click: confirmOkAction.bind(this, data) },
            cancelButton: { click: confirmCancelAction.bind(this) },
            position: { X: 'center', Y: 'center' },
            closeOnEscape: true
        });
    }

    const confirmOkAction = (data: any) => {
        dispatch(remove(data.uniqueId || ''))
        dialogObj.hide();
        setDisplay('block');
    }
    const confirmCancelAction = () => {
        dialogObj.hide();
        setDisplay('block');
    }

    const handleAddSurveyClick = () => {
        setDialogStatus({ hideDialog: true });
    };

    const dialogClose = () => {
        setDialogStatus({ hideDialog: false });
    }

    const onBeforeOpen = (args: BeforeOpenEventArgs) => {
        args.maxHeight = '80%';
    };

    const created = () => {
        gridInstance.showMaskRow();
    };

    const updateLogin = async (props: any) => {
        await dispatch(updateFormLoginRequired(props.uniqueId));
       // document.getElementById(`checkbox-${props.uniqueId}`).checked = true;
    }


    const isLoginRequiredColumnTemplate = (props: any) => {
        return <CheckBoxComponent onClick={() => { updateLogin(props) }} cssClass='e-inherit' checked={props.isLoginRequired}></CheckBoxComponent>
    }

    const isMultipleSubmissionsAllowColumnTemplate = (props: any) => {
        return <CheckBoxComponent id={`checkbox-${props.uniqueId}`} onClick={(e: any) => { dispatch(updateFormSubmissions(props.uniqueId)) }} cssClass='e-inherit' checked={props.isMultipleSubmissionsAllow}></CheckBoxComponent>
    }

    const actionColumnTemplate = (props: any) => {

        const handleAction = (event: any) => {
            if (event.item.text === "Share") {
                navigator.clipboard.writeText(`${window.location.origin}/run/${props.uniqueId}?creationType=${props.creationType}`);
            }
            else if (event.item.text === "Delete") {
                deleteForm(props)
            }
        };

        const items: ItemModel[] = [
            {
                text: 'Run',
                iconCss: 'bi bi-collection-fill',
                url: `/run/${props.uniqueId}?creationType=${props.creationType}`
            },
            {
                text: 'Edit',
                iconCss: 'bi bi-wrench-adjustable',
                url: `/formlist/edit/${props.uniqueId}?creationType=${props.creationType}`
            },
            {
                text: 'Share',
                iconCss: 'bi bi-share-fill',
            },
            {
                text: 'Responses',
                iconCss: 'e-icons e-edit',
                url: `/formlist/results-list/${props.uniqueId}?creationType=${props.creationType}`
            },
            {
                text: 'Delete',
                iconCss: 'bi bi-trash3-fill',
            }];
        return (<div className='image'>
            <DropDownButtonComponent select={handleAction} cssClass='e-flat e-primary e-caret-hide' items={items} iconCss='bi bi-gear-fill'></DropDownButtonComponent>
        </div>);
    }

    return (

        <div className='container' id='dialog-target'>
            <div className='row'>
                <div className="col-md-12">
                    <DialogComponent width='50%' target='#dialog-target' close={dialogClose} header='Create Form' visible={dialogStatus.hideDialog} showCloseIcon={true} beforeOpen={onBeforeOpen}>
                        <AddSurvey />
                    </DialogComponent>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-6">
                    <div className='lead'>My Forms</div>
                </div>
                <div className="col-md-6 text-end">
                    <ButtonComponent onClick={handleAddSurveyClick} cssClass='e-primary'>Create Form</ButtonComponent>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <GridComponent id="overviewgrid" ref={(grid: any) => { gridInstance = grid; }} created={created} allowMultiSorting={true} dataSource={surveys} allowPaging={true} pageSettings={{ pageSize: 10, pageSizes: true }} loadingIndicator={{ indicatorType: 'Shimmer' }} enableHover={false} height='70vh' filterSettings={filterSettings} allowFiltering={true} allowSorting={true} allowSelection={true} enableHeaderFocus={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='id' headerText='uniqueId' visible={false} isPrimaryKey={true}></ColumnDirective>
                            <ColumnDirective field='name' headerText='Name' ></ColumnDirective>
                            <ColumnDirective field='creationType' headerText='Survey Type'></ColumnDirective>
                            <ColumnDirective field='createdUser' headerText='Created By'></ColumnDirective>
                            <ColumnDirective field='isLoginRequired' headerText='Login Required' template={isLoginRequiredColumnTemplate}></ColumnDirective>
                            <ColumnDirective field='isLoginRequired' headerText='Multiple Submissions' template={isMultipleSubmissionsAllowColumnTemplate}></ColumnDirective>
                            <ColumnDirective textAlign='Right' width={100} headerText='Actions' template={actionColumnTemplate} />
                        </ColumnsDirective>
                        <Inject services={[Filter, Page, Sort]} />
                    </GridComponent>

                </div>
            </div>
        </div>
    );
};

export default Surveys