import React, { ReactNode } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface PageLayoutProps {
    children: ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
    return (
        <>
            <NavigationBar />
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
            </AuthenticatedTemplate>

            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2000}
                style={{ height: '30px'}}
                />
        </>
    );
};
