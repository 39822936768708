import React, { useState } from 'react';
import { create } from '../redux/surveys';
import { useReduxDispatch } from '../redux';
import './Surveys.css'
import './SurveyForm.css'
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useNavigate } from 'react-router';
import { formType } from '../utils/const';
import { ISurveyDefinition } from '../models/survey';
import { getUserEmail } from '../utils/user/user.util';
const SurveyForm = (): React.ReactElement => {
    const [surveyDefinition, SetSurveyDefinition] = useState<ISurveyDefinition>({ name: '', isLoginRequired: true });

    const dispatch = useReduxDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (surveyDefinition.name) {
            if(!surveyDefinition.isLoginRequired){
                surveyDefinition.isMultipleSubmissionsAllow = true;
            }
            const survey = await dispatch(create({ name: surveyDefinition.name, CreatedUser: getUserEmail(), creationType: formType.surveyForm, isLoginRequired: surveyDefinition.isLoginRequired, isMultipleSubmissionsAllow: surveyDefinition.isMultipleSubmissionsAllow }));
        }
    };

    const handleSubmitPDF = () => {
        navigate(`/upload-doc`);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <RadioButtonComponent onChange={handleSubmitPDF} label="Create form from pdf file" labelPosition="Before" />
                <hr className="bg-secondary border-2 border-top border-secondary" />
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={surveyDefinition.name}
                            onChange={(e) => SetSurveyDefinition({ ...surveyDefinition, name: e.target.value })}
                            required />
                    </div>
                    <div className="form-group">
                        <CheckBoxComponent cssClass="e-info" change={() => SetSurveyDefinition({ ...surveyDefinition, isLoginRequired: !surveyDefinition.isLoginRequired })} checked={surveyDefinition.isLoginRequired} label="Attech login form to fill form" labelPosition="Before" />
                    </div>
                    {
                        surveyDefinition.isLoginRequired && (
                            <div className="form-group">
                            <CheckBoxComponent cssClass="e-info" change={() => SetSurveyDefinition({ ...surveyDefinition, isMultipleSubmissionsAllow: !surveyDefinition.isMultipleSubmissionsAllow })} checked={surveyDefinition.isMultipleSubmissionsAllow} label="Allow multiple submissions of form" labelPosition="Before" />
                        </div>
                        )
                    }
                    <div className="form-group">
                        <ButtonComponent type="submit" cssClass='e-success'>Create Form</ButtonComponent>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SurveyForm;
