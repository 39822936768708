import { useParams } from 'react-router'
import { useReduxDispatch, useReduxSelector } from '../redux';
import Viewer from '../components/Viewer'
import { useEffect, useState } from 'react';
import { get } from '../redux/surveys';
import Breadcrumb from '../components/BreadcrumbComponent';
import React from 'react';

const Results = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()
    const survey = useReduxSelector(state => state.surveys.survey)
    const [breadcrumbList, setBreadcrumbList] = useState([
        {
            text: 'Home',
            link: "/"
        }
    ]);

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(id || ''))
            let _breadcrumbList = breadcrumbList
            _breadcrumbList.push({ text: `Result - ${surveyAction.payload.name}`, link: '' })
            setBreadcrumbList([..._breadcrumbList])
        })()
    }, [dispatch, id])

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12' >
                    {survey.id && (<Breadcrumb data={breadcrumbList} />)}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12' >
                    <Viewer id={id as string} />
                </div>
            </div>
        </div>
    );
}

export default Results;