import * as React from 'react';
import {
    PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, PageOrganizer, Inject
} from '@syncfusion/ej2-react-pdfviewer';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import './pdf.component.css';
import { RemovingEventArgs, SelectedEventArgs, UploaderComponent, UploadingEventArgs } from '@syncfusion/ej2-react-inputs';
import { apiBaseAddress } from '../models/survey';
import FormInput from '../components/FormInput';
import { convertToQuestionsList } from '../utils/surveyjs/surveyjs.helper';
import { BeforeOpenEventArgs, DialogComponent } from '@syncfusion/ej2-react-popups';
import Breadcrumb from '../components/BreadcrumbComponent';

function FormFilling() {
    let viewer: PdfViewerComponent;
    const [inputList, setInputList] = React.useState<any[]>([]);
    const [inputFile, setInputFile] = React.useState(String);
    const [showInputEnable, setShowInputEnable] = React.useState(false);
    const [imageList, setImageList] = React.useState<any[]>([]);
    const [pages, setPages] = React.useState(Object);
    const [dialogStatus, setDialogStatus] = React.useState({ hideDialog: false });
    const [breadcrumbList, setBreadcrumbList] = React.useState([
        {
            text: 'Home',
            link: "/"
        },
        {
            text: 'upload'
        }
    ]);

    const asyncSettings = {
        saveUrl: `${apiBaseAddress}/PDFViewer/Save`
    };
    const onUpload = (args: UploadingEventArgs) => {
        args.customFormData = [{ "files": args.fileData.name }];
    }

    const onSelect = (args: SelectedEventArgs) => {
        setInputList([]);
        args.customFormData = [{ "files": args.filesData[0].name }];
        if (args.filesData.length) {
            const file = args.filesData[0].rawFile as any;
            const reader = new FileReader();
            reader.addEventListener('load', function () {
                console.log("base 64", reader.result as string)
                setInputFile(reader.result as string);
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    }

    const onSuccess = async (args: any) => {
        if (args.operation === "upload") {
            setImageList(JSON.parse(args.e.target.response).data)
        }
    }

    const onRemove = async (args: RemovingEventArgs) => {
        setInputList([]);
        setShowInputEnable(false);
        let viewer = document.getElementById('container') as any;
        viewer.ej2_instances[0].unload();
    }

    const documentLoad = (event: any) => {
        setInputList([]);
        let viewer = document.getElementById('container') as any;
        if (viewer) {
            setPages(JSON.parse(event.pageData));
            setShowInputEnable(true);
        }
    }

    const showInputClick = () => {
        setDialogStatus({ hideDialog: true });
        let viewer = document.getElementById('container') as any;
        const question = convertToQuestionsList([...viewer.ej2_instances[0].formFieldCollections]);
        setInputList([...question]);
    }
    const dialogClose = () => {
        setDialogStatus({ hideDialog: false });
    }

    const onBeforeOpen = (args: BeforeOpenEventArgs) => {
        args.maxHeight = '80%';
    };

    const formFieldDoubleClick = (args: any) => {
        args.cancel = true;
        setDialogStatus({ hideDialog: true });
    };

    return (
        <div className="container" id='dialog-target'>
            <div className='row'>
                <div className='col-md-12' >
                    {(<Breadcrumb data={breadcrumbList} />)}
                </div>
            </div>
            
            <div className='row'>
                <div className='col-md-12' >
                    <UploaderComponent
                        name="files"
                        multiple={false}
                        autoUpload={true}
                        type="file"
                        uploading={onUpload}
                        success={onSuccess}
                        selected={onSelect}
                        removing={onRemove}
                        allowedExtensions=".pdf"
                        asyncSettings={asyncSettings} />
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <ButtonComponent disabled={(!inputList.length) && (!showInputEnable)} onClick={showInputClick} cssClass='e-primary'>View Input</ButtonComponent>
                    <DialogComponent width='80%' target='#dialog-target' close={dialogClose} header='Dialog' visible={dialogStatus.hideDialog} showCloseIcon={true} beforeOpen={onBeforeOpen}>
                        <FormInput pages={pages} imageList={imageList} inputList={inputList}></FormInput>
                    </DialogComponent>

                </div>
            </div>
            <div className='row'>
                <div className='col-md-12' >
                    {/* Render the PDF Viewer */}
                    {
                        inputFile &&
                        (
                            <PdfViewerComponent
                                documentPath={inputFile}
                                enableTextSelection={true}
                                enablePrint={true}
                                id="container"
                                documentLoad={documentLoad}
                                formFieldDoubleClick={formFieldDoubleClick}
                                ref={(scope: any) => { viewer = scope; }}
                                // serviceUrl={`${apiHost}/pdfviewer`}
                                resourceUrl="https://cdn.syncfusion.com/ej2/24.1.41/dist/ej2-pdfviewer-lib"
                                enableFormFieldsValidation={true} showNotificationDialog={true} style={{ 'height': '70vh' }}>
                                <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, PageOrganizer]} />
                            </PdfViewerComponent>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
export default FormFilling;
