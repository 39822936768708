import React from "react";
import { Nav, Navbar, Dropdown, DropdownButton } from "react-bootstrap";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { AppBarComponent } from "@syncfusion/ej2-react-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Link, useNavigate } from "react-router-dom";
import {
  DropDownButtonComponent,
  ItemModel,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import { getUserEmail, setActiveAccount } from "../utils/user/user.util";
// import logo from '../images/logo.svg'
import logo from "../images/baystream.ico";
import "../styles/navigationBar.css";

export const NavigationBar: React.FC = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    setActiveAccount(activeAccount);
  }

  const handleLoginRedirect = () => {
    instance
      .loginRedirect(loginRequest)
      .then((data) => { })
      .catch((error) => console.log(error));
  };

  const handleAction = (event: MenuEventArgs) => {
    if (event.item.text === "Log Out") {
      instance.logoutRedirect();
    } else if (event.item.text === "Create PDF Form") {
      navigate(`/upload-doc`);
    } else if (event.item.text === "My Form" || event.item.text === "Form Management") {
      navigate(`/formlist`);
    }
  };

  const items: ItemModel[] = [
    {
      text: "My Form",
      iconCss: "bi bi-collection-fill",
    },
    {
      text: "Form Management",
      iconCss: "bi bi-wrench-adjustable",
    },
    {
      text: "Create PDF Form",
      iconCss: "bi bi-file-earmark-fill",
    },
    {
      text: "Log Out",
      iconCss: "e-ddb-icons e-logout",
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <AppBarComponent colorMode="Primary">
          <div className="row justify-content-around">
            <div className="col-4">
              <ButtonComponent
                ref={(regularBtn) => (regularBtn = regularBtn)}
                aria-label="menu"
                cssClass="e-inherit menu"
              ></ButtonComponent>
              <Link className="menu-link" to="/formlist">
                <ButtonComponent cssClass="e-inherit">
                  <img
                    src={logo}
                    className="nav-logo me-2"
                    style={{ width: "25px" }}
                  />
                  Form Management
                </ButtonComponent>
              </Link>
            </div>
            <div className="col-4 text-end">
              <AuthenticatedTemplate>
                {/* <Link className='menu-link' to="/formlist"><ButtonComponent cssClass='e-inherit'>My forms</ButtonComponent></Link>
                        <Link className='menu-link' to="/upload-doc"><ButtonComponent cssClass='e-inherit'>Create form from PDF file</ButtonComponent></Link> */}
                <div className="e-appbar-spacer"></div>
                <DropDownButtonComponent
                  className="menu-link"
                  select={handleAction}
                  cssClass="e-inherit"
                  items={items}
                  iconCss="e-ddb-icons e-profile"
                  style={{
                    backgroundColor: "white",
                    color: "#0078d4",
                    borderRadius: "5px",
                  }}
                >
                  <span className="email-color">{getUserEmail()}</span>
                </DropDownButtonComponent>
              </AuthenticatedTemplate>
            </div>
          </div>
        </AppBarComponent>
      </div>
    </div>
  );
};
