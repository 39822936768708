import { CUSTOM_TYPE } from "../components/SurveyCreatorComponent";

export const getInputType = () => {
    const inputs = INPUTTYPE as any
    return Object.keys(inputs)
        .map((key: any) => ({ Name: inputs[key], Value: key }))
}

export enum INPUTTYPE {
    checkbox = "Checkbox",
    color = "Color",
    date = "Date",
    dropdown = "Dropdown",
    "datetime - local" = "DateTime",
    email = "Email",
    month = "Month",
    number = "Number",
    password = "Password",
    tel = "Phone number",
    text = "Text",
    time = "Time",
    url = "URL",
    week = "Week",
    radiogroup = "Radio group",
    comment = "Multiline TextBox",
    "single-chars-input" = "Single Chars Input"
}


export const checkbox = "checkbox";
export const dropdown = "dropdown";
export const radiogroup = "radiogroup";
export const comment = "comment"
export const singleCharsInput = CUSTOM_TYPE

export enum formType   {
    pdfForm = "PDF FORM",
    surveyForm = "SURVEY FORM"
}